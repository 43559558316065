<template>
  <div class="row site-quick-actions">
    <h4 class="text-dark">{{ $t(headerLabel) }}</h4>

    <div class="col-12">
      <div class="resource-block mb-3">
        <v-popover placement="bottom-start">
          <a>
            <b>1.</b> {{ $t('Download FileCloud Server') }}
          </a>

          <template #popover>
            <div class="downloads__download-button-popover">
              <ul v-for="(option, index) in serverDownloadOptions" :key="index">
                <p>{{ option.title }}</p>

                <li
                  v-for="(link, linkIndex) in option.links"
                  :key="linkIndex"
                  v-close-popover
                >
                  <a
                    :href="link.url ? link.url : '#'"
                    :target="link.url ? '_blank' : '_self'"
                    @click.prevent="onDownload(link)"
                  >
                    <Icon
                      :family="link.iconFamily || 'fab'"
                      :name="link.icon"
                    />

                    <div class="d-flex align-items-center">
                      <h4>{{ link.title }}</h4>
                      <span v-if="link.description">{{
                        link.description
                      }}</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </template>
        </v-popover>
        <p class="mb-0">
          {{ $t('Run FileCloud Server on a wide variety of platforms') }}
        </p>
      </div>
    </div>

    <div v-if="licenseURL" class="col-12">
      <div class="resource-block mb-3">
        <a :href="licenseURL" target="_blank" class="mb-1"
          ><b>2.</b> {{ $t('Download your license') }}</a
        >
        <p class="mb-0">
          {{
            $t(
              'You will need your license to install activate your FileCloud installation'
            )
          }}
        </p>
      </div>
    </div>

    <div class="col-12">
      <div class="resource-block mb-3">
        <a
          href="https://www.getfilecloud.com/supportdocs/display/cloud/Getting+Started+Tutorials"
          target="_blank"
          class="mb-1"
          ><b>{{ licenseURL ? 3 : 2 }}</b>
          {{ $t('Learn the FileCloud basics') }}</a
        >
        <p class="mb-0">{{ $t('Watch videos on how to get started.') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';

import { downloadSections } from '@/constants/downloadsPage';

export default {
  components: {
    Icon,
  },
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      downloadLinks: {},
    };
  },
  computed: {
    headerLabel() {
      return (this.site.istrial) ? 
        `Getting Started with your ${this.site.iscommunity ? 'Community' : 'Server'} Trial` :
        `Getting Started with FileCloud ${this.site.iscommunity ? 'Community' : 'Server'}`;
    },
    licenseURL() {
      if (!this.site || this.site.licensegroup === 'Online') return '';
      if (this.site.licensegroup === 'Community')
        return '/core/?op=gettrialcommunitylicense';
      if (this.site.istrial) return '/core/?op=gettriallicense';
      if (this.site.id && typeof this.site.id === 'string')
        return `/core/?op=getlicense&saleid=${this.site.id}`;
      return '';
    },
    serverDownloadOptions() {
      return downloadSections.find((section) => section.id === 'server')
        .downloadOptions;
    },
  },
  async mounted() {
    const response = await this.$store.dispatch('core/getDownloads');

    if (!response.ok) return;

    response.data.setting.forEach((link) => {
      this.downloadLinks[link.param] = link.value;
    });
  },
  methods: {
    onDownload(link) {
      const url = this.downloadLinks[link.id];

      if (!url || typeof url !== 'string') return;

      window.open(url);
    },
  },
};
</script>

<style></style>
