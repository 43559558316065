<template>
  <div v-if="hasOptionsToShow" class="row site-quick-actions">
    <h4 class="text-dark">{{ $t('Quick Actions') }}</h4>

    <div v-if="showBuyExtraSeats" class="col-12">
      <div class="resource-block mb-3">
        <a
          class="mb-1"
          @click="$emit('buyExtraSeats')"
          >{{ $t('Buy extra seats') }}</a
        >
        <p class="mb-0">{{ $t('Expand the number of licenses.') }}</p>
      </div>
    </div>

    <div v-if="showBuyExtraStorage" class="col-12">
      <div class="resource-block mb-3">
        <a
          class="mb-1"
          @click="$emit('buyExtraStorage')"
          >{{ $t('Buy extra storage') }}</a
        >
        <p class="mb-0">{{ $t('Additional storage for your FileCloud Online.') }}</p>
      </div>
    </div>

    <div v-if="showResetSitePassword" class="col-12">
      <div class="resource-block mb-3">
        <a
          class="mb-1"
          @click="$emit('resetAdminPassword')"
          >{{ $t('Reset site admin password') }}</a
        >
        <p class="mb-0">{{ $t('Generate a new password for FileCloud Admin Portal.') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showBuyExtraSeats() {
      return !this.site?.istrial;
    },
    showBuyExtraStorage() {
      return false;
      // return !this.site?.istrial && this.site?.licensegroup === 'Online';
    },
    showResetSitePassword() {
      return this.site?.teamname && this.site?.licensegroup === 'Online' && this.site?.daysremaining > 0;
    },
    hasOptionsToShow() {
      return (
        this.showBuyExtraSeats ||
        this.showBuyExtraStorage ||
        this.showResetSitePassword
      );
    },
    licenseURL() {
      if (!this.site) return '';
      if (this.site.licensegroup === 'Community')
        return '/core/?op=gettrialcommunitylicense';
      if (this.site.istrial) return '/core/?op=gettriallicense';
      if (this.site.id && typeof this.site.id === 'string')
        return `/core/?op=getlicense&saleid=${this.site.id}`;
      return '';
    },
  },
};
</script>

<style>
</style>