<template>
  <Modal
    name="reset-admin-password"
    :title="$t('Reset Admin Password')"
    class="fixed-top checkout-modal"
    @before-open="onOpen"
    @before-close="onClose"
  >
    <div class="product-section">
      <Loader v-if="isLoading" loading full />

      <div class="modal-heading">
        <h2>{{ $t('Reset Admin Password') }}</h2>
        <p v-if="site.deploymenturl">
          {{
            $t(`This will reset the admin password for ${site.deploymenturl}`)
          }}
        </p>
      </div>

      <div>
        <p>{{$t('Proceeding, you will receive an email containing a new temporary password.')}}</p>
      </div>


    </div>
    <template #footer>
      <div class="row">
        <div class="col">
          <SupportBlock minified />
        </div>
        <div class="col-auto">
          <button
            class="btn btn-outline-primary px-5 ml-auto mr-3"
            @click="onClose"
          >
            {{ $t('Close') }}
          </button>
          <button
            class="btn btn-primary px-5 ml-auto ml-3"
            type="submit"
            @click="onSubmit"
          >
            {{ $t('Reset Password') }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Loader from 'common/components/Loader.vue';
import SupportBlock from '@/components/Partials/SupportBlock.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'ExtendTrial',
  components: {
    Loader,
    Modal,
    SupportBlock,
  },
  data() {
    return {
      errors: {},
      site: {},
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.loading['sites/resetAdminPassword'];
    },
    core() {
      return this.$store.state.core;
    },
    user() {
      return this.$store.state.core.user;
    },
    form() {
      return {
        teamname: this.site.teamname,
      };
    },
  },
  methods: {
    onOpen({ params }) {
      if (params && params.site) {
        this.site = params.site;
      }
    },
    async onSubmit() {
      this.errors = {};

      const response = await this.$store.dispatch('sites/resetAdminPassword', this.site.teamname);

      if (response.ok && !response.error) {
        this.$toast.info('Password changed successfully');
      } else {
        this.$toast.error(response.error);
      }

    },
    onClose() {
      this.site = {};
      this.$modal.hide('reset-admin-password');
    },
  },
};
</script>