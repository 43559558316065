<template>
  <div class="row no-gutters site-quick-actions">
    <div class="row">
      <h4 class="text-dark">
        {{ $t('Getting Started with your FileCloud Community Server') }}
      </h4>
    </div>

    <div class="col-12 ml-lg-4">
      <div class="community-resource-block mb-3">
        <div class="d-flex align-items-center">
          <div class="icon">
            <LaptopArrowDownIcon />
          </div>

          <div class="description">
            <b>1</b>

            <h5 class="mb-0">
              {{ $t('Download FileCloud Community Server') }}
            </h5>
          </div>
        </div>

        <v-popover placement="bottom-start">
          <button class="btn-action">Download</button>

          <template #popover>
            <div class="downloads__download-button-popover">
              <ul v-for="(option, index) in serverDownloadOptions" :key="index">
                <p>{{ option.title }}</p>

                <li
                  v-for="(link, linkIndex) in option.links"
                  :key="linkIndex"
                  v-close-popover
                >
                  <a
                    :href="link.url ? link.url : '#'"
                    :target="link.url ? '_blank' : '_self'"
                    @click.prevent="onDownload(link)"
                  >
                    <Icon
                      :family="link.iconFamily || 'fab'"
                      :name="link.icon"
                    />

                    <div class="d-flex align-items-center">
                      <h4>{{ link.title }}</h4>
                      <span v-if="link.description">{{
                        link.description
                      }}</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </template>
        </v-popover>
      </div>
    </div>

    <div v-if="licenseURL" class="col-12 ml-lg-4">
      <div class="community-resource-block mb-3">
        <div class="d-flex align-items-center">
          <div class="icon">
            <LicenseIcon />
          </div>

          <div class="description">
            <b>2</b>

            <h5 class="mb-0">{{ $t('Get Your License') }}</h5>

            <p class="mb-0">
              {{
                $t(
                  'You will need your license to install and activate your FileCloud installation'
                )
              }}
            </p>

            <div v-if="site.licenseidentifier" class="d-flex flex-column">
              <div class="d-flex align-items-center" style="gap:4px;">
                <span class="text-light">{{ $t('Your license identifier is') }}</span>
                <SiteLicenseIdentifier
                  :license-identifier="site.licenseidentifier"
                />
              </div>

              <p class="text-muted m-0" style="font-size: 10px">
                This is not your license. Please download the key file and apply
                it accordingly.
              </p>
            </div>
          </div>
        </div>

        <a :href="licenseURL" target="_blank" class="btn-action">{{
          $t('Get License')
        }}</a>
      </div>
    </div>

    <div class="col-12 ml-lg-4">
      <div class="community-resource-block mb-3">
        <div class="d-flex align-items-center">
          <div class="icon">
            <TutorialIcon />
          </div>

          <div class="description">
            <b>{{ licenseURL ? 3 : 2 }}</b>

            <h5 class="mb-0">{{ $t('Learn FileCloud basics') }}</h5>

            <p class="mb-0">
              {{ $t('Watch tutorials on how to get started') }}
            </p>
          </div>
        </div>

        <a
          href="https://www.filecloud.com/supportdocs/fcdoc/latest/server/video-tutorials"
          target="_blank"
          class="btn-action"
        >
          {{ $t('Tutorials') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
import LaptopArrowDownIcon from '@/assets/laptop-arrow-down.svg';
import LicenseIcon from '@/assets/license.svg';
import TutorialIcon from '@/assets/tutorial.svg';
import { downloadSections } from '@/constants/downloadsPage';
import SiteLicenseIdentifier from './SiteLicenseIdentifier.vue';

export default {
  components: {
    Icon,
    LaptopArrowDownIcon,
    LicenseIcon,
    TutorialIcon,
    SiteLicenseIdentifier,
  },
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      downloadLinks: {},
    };
  },
  computed: {
    licenseURL() {
      return '/core/?op=gettrialcommunitylicense';
    },
    serverDownloadOptions() {
      return downloadSections.find((section) => section.id === 'server')
        .downloadOptions;
    },
  },
  async mounted() {
    const response = await this.$store.dispatch('core/getDownloads');

    if (!response.ok) return;

    response.data.setting.forEach((link) => {
      this.downloadLinks[link.param] = link.value;
    });
  },
  methods: {
    onDownload(link) {
      const url = this.downloadLinks[link.id];

      if (!url || typeof url !== 'string') return;

      window.open(url);
    },
  },
};
</script>

<style></style>
