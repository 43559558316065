<template>
  <div>
    <Loader full :loading="isLoading" />

    <div v-if="site" class="site-banner">
      <div class="container-lg">
        <div class="site-banner__info">
          <h6 v-if="site.iscommunity">FileCloud Community Edition</h6>
          <h6 v-else-if="site.spla">FileCloud SPLA</h6>
          <h6 v-else>
            FileCloud
            {{ typeof site.licensegroup === 'string' ? site.licensegroup : '' }}
          </h6>

          <div class="logo">
            <Logo
              v-if="
                isLogoFailed ||
                (site && site.ispending) ||
                (site && !site.deploymenturl)
              "
              viewBox="0 0 250 60"
            />
            <img
              v-else-if="site.deploymenturl"
              alt=""
              :src="`https://${site.deploymenturl}/core/getcustomimage?type=login`"
              @error="isLogoFailed = true"
            />
            <span
              v-if="site.istrial && !site.iscommunity"
              class="is-trial-badge"
              >trial</span
            >
          </div>

          <span v-if="site.deploymenturl">{{ site.deploymenturl }}</span>
        </div>
      </div>
    </div>

    <main v-if="site" role="main" class="main-wrapper">
      <section class="site-area__usage page-section">
        <div class="section-header">
          <h2 v-if="!site.iscommunity">{{ $t('Usage') }}</h2>
          <h2 v-else>{{ $t('Next Steps') }}</h2>
        </div>

        <div class="section-body has-divider">
          <div class="row">
            <div v-if="!site.iscommunity" class="col-12 col-lg-4">
              <div v-if="site.istrial === 1" class="col-12">
                <div class="d-flex flex-column mb-2 justify-content-between">
                  <h4 class="mb-1">{{ $t('Trial') }}</h4>

                  <SiteLicenseIdentifier
                    :license-identifier="site.licenseidentifier"
                  />
                </div>

                <div class="d-flex">
                  <div>
                    <ProgressCircular
                      :text="
                        !isLicenceExpired
                          ? $t('{daysused} / {totalduration} days', {
                              daysused:
                                licenseTotalDuration - licenseDaysRemaining ||
                                0,
                              totalduration: licenseTotalDuration || 0,
                            })
                          : $t('Expired')
                      "
                      :value="
                        !isLicenceExpired
                          ? licenseTotalDuration - licenseDaysRemaining
                          : 1
                      "
                      :show-percentage="!isLicenceExpired"
                      :color="
                        !isLicenceExpired ? undefined : 'var(--color-danger)'
                      "
                      :total-value="
                        isLicenceExpired ? 1 : licenseTotalDuration || 0
                      "
                      :size="100"
                    />
                  </div>

                  <div class="d-flex flex-column align-items-center col">
                    <h5
                      v-if="isTrial"
                      class="text-light"
                      v-html="
                        $tc(
                          'Trial <b>expired</b> | <b>{count} day left</b> in trial | <b>{count} days left</b> in trial',
                          licenseDaysRemaining <= 0 ? 0 : licenseDaysRemaining
                        )
                      "
                    />
                    <h5
                      v-else
                      class="text-light"
                      v-html="
                        $tc(
                          'License <b>expired</b> | <b>{count} day left</b> for the license | <b>{count} days left</b> for the license',
                          licenseDaysRemaining <= 0 ? 0 : licenseDaysRemaining
                        )
                      "
                    />

                    <button
                      class="btn btn-primary px-4"
                      @click="onBuyNow(buyNowURL)"
                    >
                      {{ $t('Buy Now') }}
                    </button>

                    <span class="text-light">{{ $t('or') }}</span>
                    <a class="text-center" @click="onExtendTrial">{{
                      $t('Request Extension')
                    }}</a>
                  </div>
                </div>
              </div>

              <div v-else class="license col-12">
                <div class="d-flex flex-column mb-2 justify-content-between">
                  <h4 class="mb-1">
                    {{
                      $t(
                        isTrial && !user.iscommunityedition
                          ? 'Trial'
                          : 'License'
                      )
                    }}
                  </h4>

                  <SiteLicenseIdentifier
                    :license-identifier="site.licenseidentifier"
                  />
                </div>

                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center">
                    <h3 class="m-0 mr-2">{{ licenseDaysRemaining }}</h3>
                    <span>{{ $t('days left') }}</span>
                  </div>

                  <div
                    class="d-flex align-items-center justify-content-between mt-3"
                  >
                    <div class="d-flex flex-column">
                      <span
                        ><b>{{
                          $tc('{count} seats|{count} seat|{count} seats', seats)
                        }}</b></span
                      >
                      <span v-if="product.name">{{ product.name }}</span>
                    </div>

                    <button class="btn btn-primary" @click="renewLicense">
                      {{ $t('Renew') }}
                    </button>
                  </div>
                </div>
              </div>

              <div
                v-if="site.licensegroup === 'Online' && site.deploymenturl"
                class="access col-12 col-lg-4 mt-4 mt-lg-0"
              >
                <h4 class="text-dark">{{ $t('Access') }}</h4>

                <div class="d-flex">
                  <a
                    :href="`https://${site.deploymenturl}/`"
                    target="_blank"
                    class="card"
                  >
                    <h4>{{ $t('User') }}</h4>
                    <span>
                      {{ $t('Collaborate with your team in FileCloud') }}
                    </span>
                    <p>{{ $t('Access') }}</p>
                  </a>
                  <a
                    :href="`https://${site.deploymenturl}/admin`"
                    target="_blank"
                    class="card"
                  >
                    <h4>{{ $t('Admin') }}</h4>
                    <span>
                      {{ $t('Manage your FileCloud instance') }}
                    </span>
                    <p>{{ $t('Access') }}</p>
                  </a>
                </div>
              </div>
            </div>

            <SiteGettingStarted
              v-if="site.licensegroup !== 'Online' && !site.iscommunity"
              :site="site"
              class="col-12 col-lg-4 mt-4 mt-lg-0"
            />

            <CommunityGettingStarted
              v-if="site.iscommunity"
              :site="site"
              class="col-12 col-lg-9 mt-4 mt-lg-0"
            />

            <SiteQuickActions
              :site="site"
              class="col-12 col-lg-4 mt-4 mt-lg-0"
              @buyExtraSeats="addSeats"
              @buyExtraStorage="addStorage"
              @resetAdminPassword="resetAdminPassword"
            />
          </div>
        </div>
      </section>

      <UsefulResources :has-divider="false" :site="site" />
    </main>
    <ResetAdminPasswordModal />
  </div>
</template>

<script>
import ProgressCircular from 'common/components/ProgressCircular';
import Loader from 'common/components/Loader';
import UsefulResources from '../components/Partials/UsefulResources.vue';
import ResetAdminPasswordModal from '@/components/Modals/ResetAdminPassword.vue';
import SiteQuickActions from '@/components/SiteQuickActions';
import SiteGettingStarted from '@/components/SiteGettingStarted';
import SiteLicenseIdentifier from '../components/SiteLicenseIdentifier.vue';

import Logo from '@/assets/logo.svg';

import { bytesToSize } from 'common/utils/files';
import CommunityGettingStarted from '../components/CommunityGettingStarted.vue';

export default {
  name: 'Site',
  components: {
    Logo,
    Loader,
    ProgressCircular,
    UsefulResources,
    ResetAdminPasswordModal,
    SiteQuickActions,
    SiteGettingStarted,
    CommunityGettingStarted,
    SiteLicenseIdentifier,
  },
  data() {
    return {
      isLogoFailed: false,
      isCopyingLicenseCode: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.core.user;
    },
    licenseURL() {
      if (!this.site || this.site.licensegroup == 'Online') return '';
      if (this.site.licensegroup === 'Community')
        return '/core/?op=gettrialcommunitylicense';
      if (this.site.istrial) return '/core/?op=gettriallicense';
      if (this.site.id && typeof this.site.id === 'string')
        return `/core/?op=getlicense&saleid=${this.site.id}`;
      return '';
    },
    product() {
      return (
        this.$store.getters['checkout/productByLicenseType'](
          this.site.licensetype
        ) || {}
      );
    },
    products() {
      return this.$store.getters['checkout/products'];
    },
    storageProduct() {
      return this.products[this.siteGroup] &&
        this.products[this.siteGroup].storage
        ? this.products[this.siteGroup].storage
        : {};
    },
    isLoading() {
      return (
        this.$store.state.loading['sites/getSites'] ||
        this.$store.state.loading['sites/getSite']
      );
    },
    isLicenceExpired() {
      return this.site.daysremaining <= 0;
    },
    seats() {
      return this.site.numofaccounts || 0;
    },
    licenseTotalDuration() {
      const diffTime =
        new Date(this.site.expirydate) - new Date(this.site.issuedate);

      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
    licenseDaysRemaining() {
      return this.site.daysremaining;
    },
    isTrial() {
      return !!this.site?.istrial || !!this.$route.params.trialType;
    },
    buyNowURL() {
      if (this.isTrial) {
        return 'https://portal.getfilecloud.com/ui/user/index.html#/sites/trial';
      } else {
        return `https://portal.getfilecloud.com/ui/user/index.html#/sites/${window.location.hostname}`;
      }
    },
    buyStorageURL() {
      if (this.isTrial) {
        return 'https://portal.getfilecloud.com/ui/user/index.html#/sites/trial?action=storage';
      } else {
        return `https://portal.getfilecloud.com/ui/user/index.html#/sites/${window.location.hostname}?action=storage`;
      }
    },
    site() {
      if (this.$route.params && this.$route.params.trialType) {
        return this.$store.getters['sites/getTrial'](
          this.$route.params.trialType
        );
      } else if (this.$route.params && this.$route.params.site) {
        return this.$store.getters['sites/getLicense'](this.$route.params.site);
      } else {
        return {};
      }
    },
    siteGroup() {
      if (this.site.spla) return 'serviceprovider';
      if (this.site.licensegroup === 'Online') return 'online';
      if (this.site.licensegroup === 'OnPremise') return 'onprem';
      if (this.site.licensegroup === 'Community') return 'onprem';
      return 'other';
    },
    isLegacy() {
      return (
        this.site &&
        (this.site.islegacy === 1 || Number.isInteger(this.site.sku))
      );
    },
  },
  watch: {
    $route() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.isTrial) {
        const { site } = this.$route.params;
        this.$store.dispatch('sites/getSite', site);
      }
    },
    bytesToSize,
    onExtendTrial() {
      this.$modal.show('trial-extend');
    },
    onBuyNow() {
      if (this.site.iscommunity) {
        this.$modal.show('checkout', {
          step: 'details',
          props: { group: 'other', product: 'community' },
        });
      } else if (
        this.siteGroup === 'online' ||
        this.siteGroup === 'onprem' ||
        this.siteGroup === 'serviceprovider'
      ) {
        this.$modal.show('checkout', {
          step: 'pricing',
          props: { group: this.siteGroup },
        });
      } else {
        this.$modal.show('checkout', { step: 'welcome' });
      }
    },
    async renewLicense() {
      this.$modal.show('renew-info');
      /*
      if (this.isLegacy) {
        this.$modal.show('contact-us', {
          type: 'legacy',
        });
        return;
      }

      const order = await this.$store.dispatch('checkout/getSaleOrderCart');
      this.$modal.show('checkout', {
        step: 'summary',
        props: { orderNumber: order.order_number },
      });
      await this.$store.dispatch('checkout/renewLicense', this.site.id);
      */
    },
    async addSeats() {
      this.$modal.show('renew-info');
      /*
      if (this.isLegacy) {
        this.$modal.show('contact-us', {
          type: 'legacy',
        });
        return;
      }

      const order = await this.$store.dispatch('checkout/getSaleOrderCart');
      this.$modal.show('checkout', {
        step: 'renew',
        props: {
          orderNumber: order.order_number,
          site: this.site,
          group: this.siteGroup,
        },
      });
      */
    },
    async addStorage() {
      this.$modal.show('contact-us', {
        type: 'storage',
      });

      /*
      const order = await this.$store.dispatch('checkout/getSaleOrderCart');

      this.$modal.show('checkout', {
        step: 'addstorage',
        props: {
          orderNumber: order.order_number,
          site: this.site,
          group: this.siteGroup,
        },
      });
      */
    },
    resetAdminPassword() {
      this.$modal.show('reset-admin-password', { site: this.site });
    },
  },
};
</script>

<style lang="scss">
.user-list-avatar {
  width: 24px;
  height: 24px;
  max-width: 24px;
  max-height: 24px;
}
</style>

<style lang="scss" scoped>
div.site-banner {
  display: flex;
  align-items: center;
  width: 100%;
  height: 130px;
  background: var(--bg-mid-light);
  box-shadow: 0px 1px 0px #e7e7e7;

  .container-lg {
    padding: 1rem 2rem !important;
  }

  &__info {
    display: flex;
    flex-direction: column;

    h6 {
      font-size: 14.4px;
      color: var(--text-light);
      font-weight: bold;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    div.logo {
      img,
      svg {
        width: 140px;
        height: 32px;
        margin-right: 12px;
        object-fit: contain;
      }

      span.is-trial-badge {
        font-size: 10px;
        color: var(--color-primary--foreground);
        font-weight: 500;
        background: var(--color-primary);
        width: 31px;
        height: 10px;
        display: inline-flex;
        padding: 7px 10px;
        border-radius: 29px;
        align-items: center;
        justify-content: center;
      }
    }

    span {
      color: var(--text-light);
      font-weight: 500;
      font-size: 14.4px;
    }
  }
}

main.main-wrapper {
  section {
    h2 {
      font-weight: 500;
      font-size: 28.8px;
      line-height: 29px;
      color: var(--text-dark);
    }

    h4 {
      color: var(--text-dark);
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0.2rem;
    }

    h5 {
      font-size: 14.4px;
      color: var(--text-light);
    }

    h6 {
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      color: var(--text-dark);
    }

    button {
      font-size: 14.4px;
      padding-top: 0;
      padding-bottom: 0;
      width: auto;
      max-width: 100%;
      height: 24px;
    }

    ul.link-list {
      padding: 0;
      list-style: none;

      li {
        line-height: 1.4rem;
        font-weight: 500;
        font-size: 14.4px;
        color: var(--text-light);
        a:hover {
          text-decoration: underline;
          color: var(--color-primary--hover);
        }
      }
    }
  }

  div.trial {
    button {
      font-size: 14.4px;
      padding-top: 0;
      padding-bottom: 0;
      width: 100%;
      max-width: 134px;
      height: 24px;
    }

    span {
      font-size: 14.4px;
      color: var(--text-light);
    }

    a {
      font-size: 14.4px;
      font-weight: 500;
    }
  }

  div.license {
    h3 {
      font-size: 28px;
      font-weight: 700;
      color: var(--color-primary);

      + span {
        font-size: 14.4px;
        color: var(--text-medium);
      }
    }

    button {
      font-size: 14.4px;
      padding-top: 0;
      padding-bottom: 0;
      width: 100%;
      max-width: 94px;
      height: 24px;
    }

    span {
      font-size: 14.4px;
      color: var(--text-light);
    }
  }

  div.access {
    a.card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px 12px;
      min-width: 120px;
      height: 150px;
      flex: 1;
      text-decoration: none;
      transition: all 0.3s ease;

      &:hover {
        background: var(--color-primary);

        * {
          color: var(--color-primary--foreground);
        }
      }

      + a.card {
        margin-left: 15px;
      }

      h4 {
        font-size: 14.4px;
        font-weight: 700;
        color: var(--text-dark);
      }

      span {
        font-size: 13px;
        text-align: center;
        color: var(--text-light);
        margin-bottom: 8px;
      }

      p {
        font-size: 14.4px;
        color: var(--color-primary);
        margin: 0;
      }
    }
  }

  /* &__team {
    div.users {
      a {
        font-size: 14.4px;
      }

      ul.user-list {
        list-style: none;
        padding: 0;

        li {
          display: flex;
          align-items: center;

          + li {
            margin-top: 8px;
          }

          div:first-child {
            margin-right: 8px;
          }

          h4 {
            font-size: 14px;
            font-weight: 700;
            color: var(--text-medium);
            margin: 0;
          }

          span {
            font-size: 14.4px;
            color: var(--text-light);
          }

          button {
            padding-left: 0;
            padding-right: 0;

            i {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              font-size: 10px;
              width: 24px;
              height: 24px;
              color: var(--text-light);
              background-color: var(--bg-medium);
              border-radius: 50%;
              margin-right: 8px;
            }
          }
        }
      }
    }

    div.quota {
      h5 {
        font-size: 14.4px;
        color: var(--text-light);
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 94px;
        font-size: 13px;
        height: 24px;
      }
    }
  } */
}
</style>
